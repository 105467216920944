import PopUpWindow from './PopUp';
import './TextContent.css';
import { useState, useEffect, useRef } from "react";
import Popup from 'reactjs-popup';




import { getDatabase, ref, set, update } from "firebase/database";








const ContactForm = ({ value, close }) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState('Email is required');
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const [disabledButton, setDisabledButton] = useState(true);
    const [dateTime, setDateTime] = useState(new Date());

    const formattedDateTime = dateTime.toString();
    const formattedDate = dateTime.toISOString().split('T')[0]
    const formattedTime = dateTime.toISOString().split('T')[1].slice(0, 8);

    const textareaRef = useRef(null);

    function convertUTCToCentral(utcTime) {
        const centralTimeZone = 'America/Chicago'; // Replace with your desired Central Time zone
        const options = { timeZone: centralTimeZone, dateStyle: 'short', timeStyle: 'medium', hour12: false };
        // const formatter = new Intl.DateTimeFormat('en-US');
        const formatter = new Intl.DateTimeFormat('en-US', options);

        console.log("just here");

        console.log(formatter.format(new Date(utcTime)).split(', '));

        return formatter.format(new Date(utcTime)).split(', ');
    }

    useEffect(() => {
        setDisabledButton(name && email && subject && message && validateEmail(email) ? false : true)
        if (textareaRef.current) {
            textareaRef.current.addEventListener('input', function () {
                textareaRef.current.style.height = 'auto';
                textareaRef.current.style.height = (textareaRef.current.scrollHeight + 2) + 'px';
            });
        }
        // const intervalId = setInterval(() => {
        //     setDateTime(new Date());
        // }, 1000); // Update every second

        // return () => clearInterval(intervalId);
        console.log('formattedDateTime');
        // console.log(formattedDateTime.getTime);

        console.log(new Date().toISOString().split('T')[0]);
        console.log((new Date()));
        console.log('formattedTime');
        console.log(formattedTime);




    }, [name, email, subject, message]);




    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+\.[a-zA-Z0-9-]+[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*$/;
        if (!email) {
            setEmailError('Email is required');
        } else if (!re.test(email)) {
            setEmailError('Invalid email format');
        } else {
            setEmailError('');
            setEmail(email);

            console.log('Email:', email);
        }

        return re.test(email);
    };



    function SubmitButton() {



        return (
            <button type='submit' className='submitFormButton' disabled={disabledButton} onClick={handleSubmit}>
                Submit
            </button>
        );
    };

    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        writeContactFormData(name, email, subject, message);

        resetInputs(e);



    }

    const resetInputs = (e) => {
        Array.from(e.target.elements).forEach(element => element.value = '');




    }

    function writeContactFormData(name, email, subject, message) {
        const db = getDatabase();
        const modifiedEmail = email.replace('.', '_');
        set(ref(db, 'contactFormSubmission/' + convertUTCToCentral(new Date())[0].replaceAll('/', '-') + '/' + modifiedEmail + '/' + convertUTCToCentral(new Date())[1]), {
            name: name,
            subject: subject,
            message: message,
            time: formattedDateTime,
            thing: 'thing'
        });
    }


    return (
        <div className='formPage'>
            <h1>HELLO THERE!</h1>
            <form onSubmit={handleSubmit} onChange={(e) => console.log(e)}>
                <p>Name:</p>
                <input type="text" id='name' value={value} onChange={(e) => setName(e.target.value)} />
                <p>Email:</p>
                <input type="email" value={value} onChange={(e) => validateEmail(e.target.value)} />
                {emailError && <span style={{ color: 'red' }}> {emailError}</span>}
                <p>Subject:</p>
                <input type="text" value={value} onChange={(e) => setSubject(e.target.value)} />
                <p>Message:</p>
                <textarea id='messageInput' type="text" ref={textareaRef} value={value} onChange={(e) => setMessage(e.target.value)} />


                <Popup
                    trigger={SubmitButton()
                    }
                    position="right center"
                    closeOnDocumentClick
                    modal


                >
                    {open => (



                        <PopUpWindow popUpData={{ name, formattedDateTime }} open={open} close={close} />

                    )}


                </Popup>;
            </form>
        </div>
    );
};

export default ContactForm;
